import { Entities, Column, Node } from "../types";
import { columnIds as configColumnIds } from "./Config";

type ReorderArgs = {
  entities: Entities;
  selectedNodeIds: string[];
  source: {
    droppableId: string;
    index: number;
  };
  destination: {
    droppableId: string;
    index: number;
  };
};

/**
 * Reorder
 */
const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/**
 * With new node ids
 */
const withNewNodeIds = (column: Column, nodeIds: string[]): Column => ({
  ...column,
  id: column.id,
  nodeIds,
});

/**
 * Reorder single drag
 */
export const reorderSingleDrag = ({ entities, selectedNodeIds, source, destination }: ReorderArgs) => {
  if (source.droppableId === destination.droppableId) {
    const column = entities.columns[source.droppableId];
    const reordered = reorder(column.nodeIds, source.index, destination.index);

    const updated = {
      ...entities,
      columns: {
        ...entities.columns,
        [column.id]: withNewNodeIds(column, reordered),
      },
    };

    return {
      entities: updated,
      selectedNodeIds,
    };
  }

  return {
    entities,
    selectedNodeIds,
  };
};

/**
 * Update Entities
 */
export const entitiesUpdate = (entities: Entities, record: Node, columnId: string): Entities => {
  const columnIds = configColumnIds;
  const nodes: Node[] = [...entities.nodes];

  if (!columnIds.includes(columnId)) {
    throw new Error(`Invalid columnId: ${columnId}`);
  }

  const currentCatIndex = Number(columnId[3]);

  // Reset nodeIds for columns starting from the current category
  const resetColumns = columnIds.reduce<Record<string, Column>>((accumulator, currentColumnId) => {
    const colNumber = Number(currentColumnId[3]); // Extract the number from CATx
    if (colNumber >= currentCatIndex) {
      accumulator[currentColumnId] = {
        ...entities.columns[currentColumnId],
        nodeIds: [], // Clear nodeIds for columns from currentCatIndex onwards
      };
    } else {
      accumulator[currentColumnId] = { ...entities.columns[currentColumnId] }; // Keep others as is
    }
    return accumulator;
  }, {});

  const columns: Record<string, Column> = { ...resetColumns };

  if (record.optionNodeIds) {
    for (const nodeId of record.optionNodeIds) {
      const item = entities.nodes.find((node) => node.id === nodeId);
      // Only add active nodes
      if (item && item.conditionNode?.isActive) {
        // Add node ID to the specified column
        columns[columnId].nodeIds.push(nodeId);
      }
    }
  }

  return {
    nodes,
    columnIds,
    columns,
  };
};
