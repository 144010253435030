import { TFunction } from "i18next";

import { Country, Filters, Sorter, TableFilterTypes, TableUserDataType, UserRole, ValidtyArea } from "../../types";
import ActionCell from "./ActionCell";

import type { ColumnsType } from "antd/lib/table";
import { Space, Tag, Typography } from "antd";
import { DateWithTooltip } from "Components/Date/DateWithTooltip";

const { Text } = Typography;

const countryFilters: TableFilterTypes[] = [
  {
    value: Country.DE,
    text: Country.DE,
  },
  {
    value: Country.ES,
    text: Country.ES,
  },
  {
    value: Country.BE,
    text: Country.BE,
  },
  {
    value: Country.LU,
    text: Country.LU,
  },
  {
    value: Country.NL,
    text: Country.NL,
  },
  {
    value: Country.HU,
    text: Country.HU,
  },
];

const userRoleFilters: TableFilterTypes[] = [
  {
    value: UserRole.ACCOUNTING,
    text: UserRole.ACCOUNTING,
  },
  {
    value: UserRole.BUSINESSADMIN,
    text: UserRole.BUSINESSADMIN,
  },
  {
    value: UserRole.MAINTENANCE,
    text: UserRole.MAINTENANCE,
  },
  {
    value: UserRole.CATMAN,
    text: UserRole.CATMAN,
  },
  {
    value: UserRole.IP,
    text: UserRole.IP,
  },
  {
    value: UserRole.QA,
    text: UserRole.QA,
  },
  {
    value: UserRole.READONLY,
    text: UserRole.READONLY,
  },
  {
    value: UserRole.STORE,
    text: UserRole.STORE,
  },
  {
    value: UserRole.SUPERUSERADMIN,
    text: UserRole.SUPERUSERADMIN,
  },
  {
    value: UserRole.USERADMIN,
    text: UserRole.USERADMIN,
  },
];

const validityAreaFilter: TableFilterTypes[] = [
  {
    value: ValidtyArea["*"],
    text: ValidtyArea["*"],
  },
  {
    value: ValidtyArea.CALCULATORIC_CONDITIONS,
    text: ValidtyArea.CALCULATORIC_CONDITIONS,
  },
  {
    value: ValidtyArea.NATIONAL,
    text: ValidtyArea.NATIONAL,
  },
  {
    value: ValidtyArea.LOCAL,
    text: ValidtyArea.LOCAL,
  },
  {
    value: ValidtyArea.RETAIL_MEDIA_INTERNATIONAL,
    text: ValidtyArea.RETAIL_MEDIA_INTERNATIONAL,
  },
  {
    value: ValidtyArea.TRADEMARKETING,
    text: ValidtyArea.TRADEMARKETING,
  },
];

export const columns = (t: TFunction, sortedInfo: Sorter, filteredInfo: Filters): ColumnsType<TableUserDataType> => [
  {
    title: t("user.username"),
    dataIndex: "username",
    key: "username",
    render: (data: string) => data ?? "---",
    sorter: true,
  },
  {
    title: t("user.firstname"),
    dataIndex: "firstname",
    key: "firstname",
    render: (data: string) => data ?? "---",
    sorter: true,
  },
  {
    title: t("user.lastname"),
    dataIndex: "lastname",
    key: "lastname",
    render: (data: string) => data ?? "---",
    sorter: true,
  },
  {
    title: t("user.email"),
    dataIndex: "email",
    key: "email",
    render: (data: string) => data ?? "---",
    sorter: true,
  },
  {
    title: t("user.isIndustryPartner"),
    dataIndex: "isIndustryPartner",
    key: "isIndustryPartner",
    render: (_, record) => (
      <Space size={0} wrap>
        {record.uniqueContractPartners.map((contractPartner) => (
          <Tag color="transparent" key={contractPartner?.id ?? "*"} style={{ padding: 0, width: "20ch" }}>
            {contractPartner?.supplierShortId ? (
              <Text ellipsis title={contractPartner.name}>
                {contractPartner.supplierShortId} {contractPartner.name}
              </Text>
            ) : (
              "*"
            )}
          </Tag>
        ))}
      </Space>
    ),
    sorter: true,
  },
  {
    title: t("user.countryId"),
    dataIndex: "countryId",
    key: "countryId",
    filters: [...countryFilters],
    filteredValue: filteredInfo["countryId"] || null,
    render: (_, record) => (
      <Space size={0} wrap>
        {record.uniqueCountries.map((country) => (
          <Tag color="transparent" key={country} style={{ padding: 0 }}>
            <Text>{country}</Text>
          </Tag>
        ))}
      </Space>
    ),
  },
  {
    title: t("user.role"),
    dataIndex: "role",
    key: "role",
    filters: [...userRoleFilters],
    filteredValue: filteredInfo["role"] || null,
    render: (_, record) => (
      <Space direction="vertical" size={0}>
        {record.uniqueRoles.map((role) => (
          <Tag color="transparent" key={role}>
            <Text>{role}</Text>
          </Tag>
        ))}
      </Space>
    ),
  },
  {
    title: t("user.validityArea"),
    dataIndex: "validityArea",
    key: "validityArea",
    filters: [...validityAreaFilter],
    filteredValue: filteredInfo["validityArea"] || null,
    render: (_, record) => (
      <Space direction="vertical" size={0}>
        {record.uniqueValidityAreas.map((area) => (
          <Tag color="transparent" key={area}>
            <Text>{area}</Text>
          </Tag>
        ))}
      </Space>
    ),
  },
  {
    title: t("user.modifiedAt"),
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (date: string, record) => <DateWithTooltip date={new Date(date)} username={record.updatedBy} />,
    sorter: true,
  },
  {
    title: t("user.createdAt"),
    dataIndex: "createdAt",
    key: "createdAt",
    render: (date: string, record) => <DateWithTooltip date={new Date(date)} username={record.createdBy} />,
    sorter: true,
  },
  {
    title: t("app.actions"),
    key: "actions",
    dataIndex: "actions",
    fixed: "right",
    render: (_text, record) => (
      <ActionCell roles={record.uniqueRoles} id={record.id} firstname={record.firstname} lastname={record.lastname} />
    ),
  },
];
