import React from "react";
import { Empty } from "antd";
import { Draggable } from "@hello-pangea/dnd";
import { Node } from "../types";
import { tableColumns } from "./Config";

export const DraggableTableRow: React.FC<{
  selectedNodeIds: string[];
  draggingNodeId: string | null;
  index: number;
  record: Node;
  columnId: string;
  nodes: Node[];
}> = ({ selectedNodeIds, draggingNodeId, index, record, columnId, nodes, ...props }) => {
  if (!record && !nodes.length) {
    return (
      <tr key={index} className="ant-table-placeholder row-item" {...props}>
        <td colSpan={tableColumns.length} className="ant-table-cell">
          <div className="ant-empty ant-empty-normal">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        </td>
      </tr>
    );
  }

  const isSelected = selectedNodeIds.includes(record.id);
  const isGhosting = isSelected && draggingNodeId !== null && draggingNodeId !== record.id;

  return (
    <Draggable draggableId={record.id} index={index}>
      {(provided, snapshot) => (
        <tr
          key={record.id}
          ref={provided.innerRef}
          {...props}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`row-item ${isSelected ? "row-selected" : ""} ${
            isGhosting ? "row-ghosting" : ""
          } ${snapshot.isDragging ? "row-dragging" : ""}`}
        />
      )}
    </Draggable>
  );
};
