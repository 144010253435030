import {
  UpdateConditionTreeItemDTO,
  Entities,
  NewConditionTreeItemDTO,
  CreateConditionTreeItemDTO,
  Node,
} from "../types";

const getSortingOrder = (nodeId: string, entities: Entities): number => {
  for (const column of Object.values(entities.columns)) {
    const index = column.nodeIds.indexOf(nodeId);
    if (index !== -1) {
      return index;
    }
  }
  throw new Error(`Node with ID ${nodeId} not found in any column.`);
};

const validateNode = (
  nodeId: string,
  entities: Entities,
): Node & { conditionNode: NonNullable<Node["conditionNode"]> } => {
  const node = entities.nodes.find((n) => n.id === nodeId);
  if (!node) {
    throw new Error(`Node with ID ${nodeId} not found in entities.`);
  }
  if (!node.conditionNode) {
    throw new Error(`Node with ID ${nodeId} is missing a conditionNode.`);
  }
  return node as Node & { conditionNode: NonNullable<Node["conditionNode"]> };
};

export const mapNodeToUpdateConditionTreeItemDTO = (
  selectedNodeIds: string[],
  entities: Entities,
): UpdateConditionTreeItemDTO => {
  const buildTree = (nodeIds: string[]): UpdateConditionTreeItemDTO | null => {
    if (nodeIds.length === 0) return null;

    const nodeId = nodeIds[0];
    const node = validateNode(nodeId, entities);

    const children = buildTree(nodeIds.slice(1));

    return {
      id: node.conditionNode.id!,
      name: node.name,
      description: node.conditionNode.description,
      isDefault: node.conditionNode.isDefault,
      freetextEnabled: node.conditionNode.freetextEnabled,
      amountIsPercentage: node.conditionNode.amountIsPercentage,
      isSellout: node.conditionNode.isSellout,
      isFixedAmount: node.conditionNode.isFixedAmount,
      gkmKoart: node.conditionNode.gkmKoart,
      koartReverseCharge: node.conditionNode.koartReverseCharge,
      isActive: node.conditionNode.isActive,
      sortingOrder: getSortingOrder(node.id, entities),
      processingSystem: node.conditionNode.processingSystem,
      isStockProtection: node.conditionNode.isStockProtection,
      isMshParkingIn: node.conditionNode.isMshParkingIn,
      capOnContractIsAllowed: node.conditionNode.capOnContractIsAllowed,
      koartParkingOut: node.conditionNode.koartParkingOut,
      koartSelloutAsSellin: node.conditionNode.koartSelloutAsSellin,
      nameI18n: node.conditionNode.nameI18n,
      koartInternational: node.conditionNode.koartInternational,
      parkingInKoartForParkingOut: node.conditionNode.parkingInKoartForParkingOut,
      condFulfillmentBaseId: node.conditionNode.condFulfillmentBaseId,
      isFinancingCondition: node.conditionNode.isFinancingCondition,
      isGmsDistributionRelevant: node.conditionNode.isGmsDistributionRelevant,
      popType: node.conditionNode.popType,
      isServiceCondition: node.conditionNode.isServiceCondition,
      children: children ? [children] : [],
      apiRelevant: node.conditionNode.apiRelevant,
      costPayment: node.conditionNode.costPayment,
      buzzword: node.conditionNode.buzzword,
    };
  };

  const result = buildTree(selectedNodeIds);

  if (!result) {
    throw new Error(
      "Failed to map nodes to UpdateConditionTreeItemDTO: No valid tree could be built from the selected node IDs.",
    );
  }

  return result;
};

export const mapNodeToCreateConditionTreeItemDTO = (
  selectedNodeIds: string[],
  entities: Entities,
): CreateConditionTreeItemDTO => {
  const firstInvalidIndex = selectedNodeIds.findIndex((nodeId) => {
    const node = entities.nodes.find((n) => n.id === nodeId);
    return !node?.conditionNode?.id;
  });

  if (firstInvalidIndex === -1) {
    throw new Error("All selected nodes have conditionNode.id, nothing to map.");
  }

  const parentNodeId = selectedNodeIds[firstInvalidIndex - 1];
  const parentNode = validateNode(parentNodeId, entities);

  const parentId = parentNode.conditionNode.id!;

  const buildTree = (nodeIds: string[]): NewConditionTreeItemDTO | null => {
    if (nodeIds.length === 0) return null;

    const nodeId = nodeIds[0];
    const node = validateNode(nodeId, entities);

    const children = buildTree(nodeIds.slice(1));

    return {
      name: node.name,
      description: node.conditionNode?.description,
      isDefault: node.conditionNode?.isDefault ?? false,
      freetextEnabled: node.conditionNode?.freetextEnabled ?? false,
      amountIsPercentage: node.conditionNode?.amountIsPercentage ?? false,
      isSellout: node.conditionNode?.isSellout ?? false,
      isFixedAmount: node.conditionNode?.isFixedAmount ?? false,
      gkmKoart: node.conditionNode?.gkmKoart,
      koartReverseCharge: node.conditionNode?.koartReverseCharge,
      isActive: node.conditionNode?.isActive ?? false,
      sortingOrder: getSortingOrder(node.id, entities),
      processingSystem: node.conditionNode?.processingSystem ?? "",
      isStockProtection: node.conditionNode?.isStockProtection ?? false,
      isMshParkingIn: node.conditionNode?.isMshParkingIn ?? false,
      capOnContractIsAllowed: node.conditionNode?.capOnContractIsAllowed ?? false,
      koartParkingOut: node.conditionNode?.koartParkingOut,
      koartSelloutAsSellin: node.conditionNode?.koartSelloutAsSellin,
      nameI18n: node.conditionNode?.nameI18n,
      koartInternational: node.conditionNode?.koartInternational,
      parkingInKoartForParkingOut: node.conditionNode?.parkingInKoartForParkingOut,
      condFulfillmentBaseId: node.conditionNode?.condFulfillmentBaseId,
      isFinancingCondition: node.conditionNode?.isFinancingCondition ?? false,
      isGmsDistributionRelevant: node.conditionNode?.isGmsDistributionRelevant ?? false,
      popType: node.conditionNode?.popType,
      isServiceCondition: node.conditionNode?.isServiceCondition ?? false,
      children: children ? [children] : [],
      apiRelevant: node.conditionNode?.apiRelevant ?? false,
      costPayment: node.conditionNode?.costPayment,
      buzzword: node.conditionNode?.buzzword,
    };
  };

  const newConditionTreeItemDTO = buildTree(selectedNodeIds.slice(firstInvalidIndex));

  if (!newConditionTreeItemDTO) {
    throw new Error(
      "Failed to map nodes to NewConditionTreeItemDTO: No valid tree could be built from the selected node IDs.",
    );
  }

  return {
    newConditionTreeItemDTO,
    parentId,
  };
};
