import { createContext } from "react";
import { UserType } from "../../types";

const AppContext = createContext({} as AppContextInterface);

export default AppContext;

interface AppContextInterface {
  user: UserType;
  isSuperUserAdmin: boolean;
  isBusinessAdmin: boolean;
  isMaintenance: boolean;
  serverVersion: string;
}
