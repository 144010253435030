import type { ColumnsType } from "antd/es/table";
import { Column, ConditionNode, Entities, Node, NonUpdateableFields } from "../types";
import { Cell } from "./Cell";
import React from "react";

export const columnIds: string[] = ["CAT1", "CAT2", "CAT3", "CAT4"];
export const columns: Record<string, Column> = {
  CAT1: { id: "CAT1", title: "Category 1", nodeIds: [] },
  CAT2: { id: "CAT2", title: "Category 2", nodeIds: [] },
  CAT3: { id: "CAT3", title: "Category 3", nodeIds: [] },
  CAT4: { id: "CAT4", title: "Category 4", nodeIds: [] },
};

export const initialEntities: Entities = {
  nodes: [],
  columnIds,
  columns,
};

export const tableColumns: ColumnsType<Node> = [
  {
    title: "",
    dataIndex: "id",
    render: (text, record) => <Cell record={record} />,
  },
];

export const createEmptyConditionNode = (conditionNode: ConditionNode): ConditionNode => ({
  rootId: conditionNode.rootId,
  parent: conditionNode.id,
  name: "",
  description: undefined,
  isDefault: false,
  freetextEnabled: false,
  amountIsPercentage: false,
  isSellout: false,
  isFixedAmount: false,
  gkmKoart: undefined,
  koartReverseCharge: undefined,
  isActive: true,
  sortingOrder: 0,
  processingSystem: "",
  depth: 0,
  path: "",
  pathIds: "",
  options: undefined,
  isStockProtection: false,
  isMshParkingIn: false,
  capOnContractIsAllowed: false,
  koartParkingOut: undefined,
  koartParkingIn: undefined,
  koartSelloutAsSellin: undefined,
  nameI18n: undefined,
  koartInternational: undefined,
  parkingInKoartForParkingOut: undefined,
  condFulfillmentBaseId: undefined,
  isFinancingCondition: false,
  isGmsDistributionRelevant: false,
  popType: undefined,
  isServiceCondition: false,
  apiRelevant: false,
  costPayment: undefined,
  buzzword: undefined,
});

export const nonUpdateableFields: (keyof NonUpdateableFields)[] = Object.keys({
  processingSystem: undefined,
  amountIsPercentage: undefined,
  isSellout: undefined,
  isFixedAmount: undefined,
  isStockProtection: undefined,
  isMshParkingIn: undefined,
  capOnContractIsAllowed: undefined,
  condFulfillmentBaseId: undefined,
  isServiceCondition: undefined,
}) as (keyof NonUpdateableFields)[];

export const ValidityAreas = [
  { label: "Local", value: "LOCAL" },
  { label: "National", value: "NATIONAL" },
  { label: "Calculatoric conditions", value: "CALCULATORIC_CONDITIONS" },
  { label: "Trade marketing", value: "TRADEMARKETING" },
  { label: "Retail media international", value: "RETAIL_MEDIA_INTERNATIONAL" },
];

export const countries = [
  { label: "Germany", value: "DE" },
  { label: "Spain", value: "ES" },
];

export const PerformanceProofTypes = [
  { label: "condition_proof_of_performance.Without", value: "Without" },
  { label: "condition_proof_of_performance.ProofOfRevenue", value: "ProofOfRevenue" },
  { label: "condition_proof_of_performance.ProofOfRevenueAndAdvertisement", value: "ProofOfRevenueAndAdvertisement" },
  { label: "condition_proof_of_performance.Advertisement", value: "Advertisement" },
  { label: "condition_proof_of_performance.ProofOfOrder", value: "ProofOfOrder" },
  { label: "condition_proof_of_performance.ProofOfPurchase", value: "ProofOfPurchase" },
  { label: "condition_proof_of_performance.ProofOfStock", value: "ProofOfStock" },
  { label: "condition_proof_of_performance.Other", value: "Other" },
];

export const ProcessingSystems = [
  { label: "Automatic", value: "Automatic" },
  { label: "Manual", value: "Manual" },
  { label: "DistributionKey", value: "DistributionKey" },
  { label: "Gkm", value: "Gkm" },
];

export const CondFulfillmentBaseIds = [
  { label: "Sales piece", value: "5" },
  { label: "Sales net", value: "6" },
  { label: "Last invoice price", value: "8" },
  { label: "Sales gross", value: "9" },
  { label: "Fixed amount", value: "11" },
];
