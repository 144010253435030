import styles from "../User/EditUser/UserEdit.module.scss";
import { Col, Divider, Modal, PageHeader, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Tree } from "./Components/Tree";
import { Fieldset } from "../../Components/Fieldset/Fieldset";
import { countries, ValidityAreas } from "./Components/Config";
import { useTree } from "./TreeContext";
import { useEffect, useState } from "react";
import { TreeBranchForm } from "./Components/TreeBranchForm";
import { useTreeApi } from "./TreeApiContext";

export const ConditionTreeContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isEditOpen, selectedNode, resetSelectedNode } = useTree();
  const {
    selectedCountry,
    selectedArea,
    setSelectedCountry,
    setSelectedArea,
    updateData,
    updateError,
    createdData,
    createdError,
  } = useTreeApi();
  const [hasUpdateSuccess, setHasUpdateSuccess] = useState<boolean>(false);
  const [hasCreateSuccess, setHasCreateSuccess] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    if (updateData && !hasUpdateSuccess) {
      setHasUpdateSuccess(true);
      Modal.success({
        title: t("conditionTree.successUpdateTitle"),
        content: t("conditionTree.successUpdateContent"),
      });
    }
  }, [updateData, hasUpdateSuccess, t]);

  useEffect(() => {
    if (createdData && !hasCreateSuccess) {
      setHasCreateSuccess(true);
      Modal.success({
        title: t("conditionTree.successCreateTitle"),
        content: t("conditionTree.successCreateContent"),
      });
    }
  }, [createdData, hasCreateSuccess, t]);

  useEffect(() => {
    if ((updateError || createdError) && !hasError) {
      setHasError(true);
      Modal.error({
        title: t("conditionTree.errorTitle"),
        content: t("conditionTree.errorContent"),
      });
    }
  }, [updateError, createdError, hasError, t]);

  useEffect(() => {
    resetSelectedNode();
  }, [selectedCountry, selectedArea, resetSelectedNode]);

  const handleSelectCountry = (country: any) => {
    setSelectedCountry(country);
  };

  const handleSelectArea = (area: any) => {
    setSelectedArea(area);
  };

  return (
    <div className={styles.formContainer}>
      <PageHeader
        className={styles.container}
        title={t("conditionTree.title")}
        subTitle={t("conditionTree.subTitle")}
        data-testid="conditionTree-pageHeader-container"
        onBack={() => navigate("/users-list", { state: state })}
      />
      <Fieldset title={t("conditionTree.title")}>
        <Row gutter={12}>
          <Col className="gutter-row" span={4}>
            <Select
              style={{ width: "100%" }}
              options={countries}
              defaultValue={countries[0]}
              onChange={handleSelectCountry}
            />
          </Col>
          <Col className="gutter-row" span={4}>
            <Select
              style={{ width: "100%" }}
              options={ValidityAreas}
              defaultValue={ValidityAreas[0]}
              onChange={handleSelectArea}
            />
          </Col>
        </Row>
        <Divider />
        <Tree />
      </Fieldset>
      {isEditOpen && selectedNode && <TreeBranchForm />}
    </div>
  );
};
