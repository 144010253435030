import { Column, ConditionNode, Entities, MainDataNode, Node, NonUpdateableFields } from "../types";
import { columnIds as configColumnIds } from "../Components/Config";

export const mapToEntities = (data: MainDataNode[], entities: Entities): Entities => {
  if (entities.nodes && entities.nodes.length > 0) {
    // Update existing nodes based on "_items"
    const allItems = data.flatMap((root) => root._items);

    const updatedNodes = entities.nodes.map((node) => {
      const matchingItem = allItems.find((item) => item.id === node.conditionNode?.id);
      if (matchingItem) {
        // Update the conditionNode with new data
        return {
          ...node,
          conditionNode: {
            ...matchingItem,
          },
        };
      }
      return node;
    });

    // Return updated entities
    return {
      nodes: updatedNodes,
      columnIds: entities.columnIds,
      columns: entities.columns,
    };
  }

  const nodes: Node[] = [];
  const columns: Record<string, Column> = {
    CAT1: { id: "CAT1", title: "Category 1", nodeIds: [] },
    CAT2: { id: "CAT2", title: "Category 2", nodeIds: [] },
    CAT3: { id: "CAT3", title: "Category 3", nodeIds: [] },
    CAT4: { id: "CAT4", title: "Category 4", nodeIds: [] },
  };
  const columnIds = configColumnIds;

  // Recursively process a node and its children
  const processNode = (item: ConditionNode, depth: number): string => {
    const columnId = columnIds[depth - 1];
    if (!columnId) return "";

    const nodeId = crypto.randomUUID();

    // Recursively process child nodes and collect their IDs
    const optionNodeIds: string[] = (item.options || []).map((child) => processNode(child, depth + 1));

    // Snapshot of the original non updateable values
    const nonUpdateableFieldsOriginalValues: NonUpdateableFields = {
      processingSystem: item.processingSystem,
      amountIsPercentage: item.amountIsPercentage,
      isSellout: item.isSellout,
      isFixedAmount: item.isFixedAmount,
      isStockProtection: item.isStockProtection,
      isMshParkingIn: item.isMshParkingIn,
      capOnContractIsAllowed: item.capOnContractIsAllowed,
      condFulfillmentBaseId: item.condFulfillmentBaseId || undefined,
      isServiceCondition: item.isServiceCondition,
    };

    // Add the current node to the nodes list
    nodes.push({
      id: nodeId,
      name: item.name,
      columnId,
      conditionNode: item,
      nonUpdateableFieldsOriginalValues,
      optionNodeIds,
    });

    // Add the current node ID to the corresponding column only if it's at the first level
    if (depth === 1) {
      columns.CAT1.nodeIds.push(nodeId);
    }

    return nodeId; // Return the ID of the current node for its parent to use
  };

  // Process all root-level nodes
  for (const root of data) {
    if (root.items) {
      for (const item of root.items) {
        processNode(item, 1); // Start at depth 1 for root-level items
      }
    }
  }

  return {
    nodes,
    columnIds,
    columns,
  };
};
