import React from "react";
import { useQuery } from "react-query";
import { MainDataNode } from "../types";
import { resolver } from "../../../Config/serviceConfig";

export const useGetConditionTree = (
  countryId: string,
  validityArea: string,
): { treeData: MainDataNode[] | null; refetch: () => void; isLoading: boolean; isError: boolean } => {
  const { data, isLoading, isError, refetch } = useQuery(
    ["conditionTree", countryId, validityArea],
    () => resolver.fetchConditionTree(countryId, validityArea),
    {
      enabled: !!countryId && !!validityArea,
    },
  );

  const treeData = React.useMemo(() => {
    if (data && !isLoading && !isError) {
      return data.data;
    }
    return null;
  }, [data, isLoading, isError]);

  return { treeData, refetch, isLoading, isError };
};
