import { useQuery } from "react-query";
import { useState, useMemo, useCallback } from "react";
import { resolver, serviceUrl } from "../Config/serviceConfig";
import { UserRightsTypes } from "../types";

const userDataUrl = serviceUrl.getUserData();

export const useAuthorization = () => {
  const [serverVersion, setServerVersion] = useState("");

  const { data: userData, isLoading, isError } = useQuery({
    queryKey: userDataUrl,
    queryFn: () => resolver.query(userDataUrl, setServerVersion),
  });

  const checkUserRole = useCallback(
    (role: string) =>
      userData?.userRights.some((rights: UserRightsTypes) => rights.role === role),
    [userData]
  );

  const roles = useMemo(
    () => ({
      isUserAdmin: checkUserRole("USERADMIN"),
      isSuperUserAdmin: checkUserRole("SUPERUSERADMIN"),
      isBusinessAdmin: checkUserRole("BUSINESSADMIN"),
      isMaintenance: checkUserRole("MAINTENANCE"),
    }),
    [checkUserRole]
  );

  return { userData, serverVersion, roles, isLoading, isError };
};