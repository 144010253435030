import { useMutation } from "react-query";
import { CreateConditionTreeItemDTO } from "../types";
import { resolver } from "../../../Config/serviceConfig";

export const useCreateConditionTreeItem = () => {
  const {
    mutate: createConditionTreeItem,
    data,
    error,
    isLoading,
  } = useMutation(
    (item: CreateConditionTreeItemDTO) => resolver.createConditionTree(item.parentId, item.newConditionTreeItemDTO),
    {
      onError: (error: any) => {
        console.error("Error creating condition tree item:", error?.response?.data?.message || error.message);
      },
      onSuccess: (data) => {
        console.log("Condition tree item created successfully:", data);
      },
    },
  );

  return {
    createConditionTreeItem,
    data,
    error,
    isLoading,
  };
};
