import { Button, Input, PageHeader, Spin, Table, TableProps } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "ts-debounce";

import { buildOrderBy, resolver, serviceUrl } from "../../Config/serviceConfig";
import {
  TableUserDataType,
  Paging,
  UserListResponse,
  SortOrder,
  Sorter,
  WorkItemsFilterRequest,
  Filters,
} from "../../types";
import styles from "./UserList.module.scss";
import { columns } from "./UsersListColumns";
import { groupBy } from "utils/basic";
import { useDebounceEffect } from "ahooks";

const usersDataUrl = serviceUrl.getUsersList();

export function UsersList() {
  const pageSizeOptions = [10, 20, 50, 100];
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [filter, setFilter] = useState<string>(state?.filter ?? "");
  const [searchTerm, setSearchTerm] = useState<Filters>(state?.searchTerm ?? {});
  const [userList, setUserList] = useState<TableUserDataType[]>([]);
  const [numTotalRecords, setNumTotalRecords] = useState(0);
  const emptyUserList: UserListResponse = { total: 0, data: [] };
  const [sortation, setSortation] = useState<Sorter>({ order: null, columnKey: "" });

  const [paging, setPaging] = useState<Paging>(
    state?.paging ?? {
      page: 1,
      pageSize: 10,
      orderBy: [],
    },
  );

  const mutate = (users: TableUserDataType[]) => {
    return users.map((user) => {
      const uniqueRolesPerUser = Object.keys(groupBy(user.userRights, (userRight) => userRight.role));
      const uniqueValidityAreasPerUser = Object.keys(groupBy(user.userRights, (userRight) => userRight.validityArea));
      const uniqueCountriesPerUser = Object.keys(groupBy(user.userRights, (userRight) => userRight.countryId));
      const uniqueContractPartnerIds = Object.keys(
        groupBy(user.userRights, (userRight) => userRight.supplier?.supplierShortId),
      );
      const uniqueContractPartners = uniqueContractPartnerIds.map((id) => {
        return user.userRights.find((userRight) => userRight.supplier?.supplierShortId === Number(id))?.supplier;
      });

      return {
        ...user,
        uniqueValidityAreas: uniqueValidityAreasPerUser,
        uniqueRoles: uniqueRolesPerUser,
        uniqueCountries: uniqueCountriesPerUser,
        uniqueContractPartners: uniqueContractPartners,
      };
    });
  };

  const onSuccess = (response: UserListResponse) => {
    const { total, data } = response;
    setUserList(mutate(data));
    setNumTotalRecords(total);
    return response;
  };

  const buildFilter = (): WorkItemsFilterRequest => {
    const filterRequest: WorkItemsFilterRequest = {
      filter: [],
    };
    const countryFilter = searchTerm["countryId"];
    const roleFilter = searchTerm["role"];
    const validityAreaFilter = searchTerm["validityArea"];
    if (countryFilter) {
      filterRequest.filter.push([{ name: "countryId", value: countryFilter, op: "In" }]);
    }
    if (roleFilter) {
      filterRequest.filter.push([{ name: "role", value: roleFilter, op: "In" }]);
    }
    if (validityAreaFilter) {
      filterRequest.filter.push([{ name: "validityArea", value: validityAreaFilter, op: "In" }]);
    }
    if (filter !== "") {
      filterRequest.filter.push([
        { name: "firstname", value: [filter], op: "Like" },
        { name: "lastname", value: [filter], op: "Like" },
        { name: "email", value: [filter], op: "Like" },
        { name: "username", value: [filter], op: "Like" },
      ]);
      if (!isNaN(Number(filter))) {
        filterRequest.filter[0].push(
          {
            name: "supplierId",
            value: [filter],
            op: "Like",
          },
          {
            name: "supplierShortId",
            value: [Number(filter)],
            op: "In",
          },
        );
      } else {
        filterRequest.filter[0].push({
          name: "supplierName",
          value: [filter],
          op: "Like",
        });
      }
    }
    return filterRequest;
  };

  const { refetch, isFetching } = useQuery<UserListResponse>({
    queryKey: usersDataUrl,
    queryFn: async () => {
      const filter = buildFilter();
      const response = await resolver.getUsers(paging, filter);
      return onSuccess(response);
    },
    initialData: () => onSuccess(emptyUserList),
    refetchOnMount: false,
  });

  useDebounceEffect(
    () => {
      navigate("/users-list", {
        state: { filter: filter, searchTerm: searchTerm, paging: paging },
        replace: true,
      });
      refetch();
    },
    [filter, paging, searchTerm],
    { wait: 30 },
  );

  if (!userList) {
    return <Spin size="large" />;
  }

  const handleChange: TableProps<TableUserDataType>["onChange"] = (pagination, filters, sorter) => {
    setSearchTerm(filters);
    if (!Array.isArray(sorter)) {
      setPaging({
        pageSize: pagination.pageSize || paging.pageSize,
        page: pagination.current || paging.page,
        orderBy: [
          sorter.field?.toString()
            ? buildOrderBy(sorter.field?.toString(), sorter.order === ("ascend" as SortOrder))
            : paging.orderBy[0],
        ],
      });
    }
    setSortation(sorter as Sorter);
  };

  return (
    <>
      <PageHeader
        className={styles.container}
        title={t("userList.title")}
        subTitle={t("userList.subTitle")}
        data-testid="userList-pageHeader-container"
        extra={[
          <Button
            key="add-user"
            data-testid="userList-createUserButton"
            type="primary"
            onClick={() => navigate("/user/create", { state: state })}
          >
            {t("createUser.title")}
          </Button>,
        ]}
      />

      <div className={styles.content}>
        <Input.Search
          data-testid="userList-searchInput"
          className={styles.search}
          placeholder={t("userList.searchPlaceholder")}
          onChange={debounce((keyword) => setFilter(keyword.target.value ?? ""), 300)}
          defaultValue={filter}
          allowClear
        />
        <Table
          data-testid="userList-table"
          rowKey="id"
          dataSource={userList}
          columns={columns(t, sortation, searchTerm)}
          size="middle"
          onChange={handleChange}
          sortDirections={["descend", "ascend"]}
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            size: "small",
            defaultPageSize: paging.pageSize,
            showSizeChanger: true,
            pageSizeOptions: pageSizeOptions,
            hideOnSinglePage: true,
            total: numTotalRecords,
            current: paging.page,
            showLessItems: false,
          }}
          onRow={(record) => ({
            onDoubleClick: () => {
              navigate(`/user/edit/${record.id}`, { state: state });
            },
          })}
        />
      </div>
    </>
  );
}

export default UsersList;
