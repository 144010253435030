import { Button, Input, PageHeader, Table, TableProps } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "ts-debounce";

import { buildOrderBy, businessAdminUrl, resolver } from "../../../Config/serviceConfig";
import {
  ContractPartnerData,
  ContractPartnerListResponse,
  Filters,
  Paging,
  PurchaseSection,
  SortOrder,
  Sorter,
  TableContractPartnerDataType,
  WorkItemsFilterRequest,
} from "../../../types";
import styles from "./ContractPartnersList.module.scss";
import { columns } from "./ContractPartnersColumns";
import { useDebounceEffect } from "ahooks";

const contractPartnersDataUrl = businessAdminUrl.getContractPartnersList();

export function ContractPartnersList() {
  const pageSizeOptions = [10, 20, 50, 100];
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [filter, setFilter] = useState<string>(state?.filter ?? "");
  const [searchTerm, setSearchTerm] = useState<Filters>(state?.searchTerm ?? {});
  const [contractPartnerList, setContractPartnerList] = useState<TableContractPartnerDataType[]>([]);
  const [numTotalRecords, setNumTotalRecords] = useState(0);
  const [sortation, setSortation] = useState<Sorter>({ order: null, columnKey: "username" });

  const [paging, setPaging] = useState<Paging>(
    state?.paging ?? {
      page: 1,
      pageSize: 10,
      orderBy: [""],
    },
  );

  const sanitizedData = (contractPartner: ContractPartnerData[]) => {
    return contractPartner.map((contractPartner) => {
      // create an array with all the purchase sections of a user
      const purchaseSections = contractPartner.onboardedPurchaseSections?.reduce(
        (acc: string[], purchaseSection: PurchaseSection) => {
          const isPurchaseSectionAdded = acc.some((sanitizedUser: string) => sanitizedUser === purchaseSection.name);
          return isPurchaseSectionAdded ? acc : [...acc, purchaseSection.name];
        },
        [],
      );

      return {
        ...contractPartner,
        purchaseSections,
      };
    });
  };

  const onSuccess = (response: ContractPartnerListResponse) => {
    const { total, data } = response;

    setContractPartnerList(sanitizedData(data));
    setNumTotalRecords(total);
    return response;
  };

  const buildFilter = (): WorkItemsFilterRequest => {
    const filterRequest: WorkItemsFilterRequest = {
      filter: [],
    };
    const countryFilter = searchTerm["countryId"];
    const roleFilter = searchTerm["role"];
    const validityAreaFilter = searchTerm["validityArea"];
    if (countryFilter) {
      filterRequest.filter.push([{ name: "countryId", value: countryFilter, op: "In" }]);
    }
    if (roleFilter) {
      filterRequest.filter.push([{ name: "role", value: roleFilter, op: "In" }]);
    }
    if (validityAreaFilter) {
      filterRequest.filter.push([{ name: "validityArea", value: validityAreaFilter, op: "In" }]);
    }
    if (filter !== "") {
      filterRequest.filter.push([
        { name: "name", value: [filter], op: "Like" },
        { name: "purchase_section_name", value: [filter], op: "Like" },
        { name: "purchase_section_abbreviation", value: [filter], op: "Like" },
      ]);
      if (!isNaN(Number(filter))) {
        filterRequest.filter[0].push(
          {
            name: "supplierId",
            value: [filter],
            op: "Like",
          },
          {
            name: "supplierShortId",
            value: [Number(filter)],
            op: "In",
          },
        );
      }
    }
    return filterRequest;
  };

  const { refetch, isFetching } = useQuery({
    queryKey: contractPartnersDataUrl,
    queryFn: async () => {
      const filter = buildFilter();
      const response = await resolver.getContractPartnerList(paging, filter);
      return onSuccess(response);
    },
    initialData: () => onSuccess({ total: 0, data: [] }),
    refetchOnMount: false,
  });

  useDebounceEffect(
    () => {
      navigate("/contract-partners/list", {
        state: { filter: filter, searchTerm: searchTerm, paging: paging },
        replace: true,
      });
      refetch();
    },
    [paging, filter, searchTerm],
    { wait: 30 },
  );

  const handleChange: TableProps<TableContractPartnerDataType>["onChange"] = (pagination, filters, sorter) => {
    setSearchTerm(filters);
    if (!Array.isArray(sorter)) {
      setPaging({
        pageSize: pagination.pageSize || paging.pageSize,
        page: pagination.current || paging.page,
        orderBy: [
          sorter.field?.toString()
            ? buildOrderBy(sorter.field?.toString(), sorter.order === ("ascend" as SortOrder))
            : paging.orderBy[0],
        ],
      });
    }
    setSortation(sorter as Sorter);
  };

  return (
    <>
      <PageHeader
        className={styles.container}
        title={t("contractPartners.list.title")}
        subTitle={t("contractPartners.list.subTitle")}
        data-testid="contractPartnerList-pageHeader-container"
        extra={[
          <Button
            key="add-user"
            data-testid="contractPartnerList-createButton"
            type="primary"
            onClick={() => navigate("/contract-partners/create", { state })}
          >
            {t("contractPartners.addNew")}
          </Button>,
        ]}
      />
      <div className={styles.content}>
        <Input.Search
          allowClear
          data-testid="contractPartnerList-searchInput"
          className={styles.search}
          placeholder={t("contractPartners.list.searchPlaceholder")}
          onChange={debounce((keyword) => setFilter(keyword.target.value ?? ""), 300)}
          defaultValue={filter}
        />
        <Table
          data-testid="contractPartnerList-table"
          rowKey="id"
          dataSource={contractPartnerList}
          columns={columns(t, sortation, searchTerm)}
          size="middle"
          onChange={handleChange}
          sortDirections={["descend", "ascend"]}
          loading={isFetching}
          pagination={{
            position: ["bottomCenter"],
            size: "small",
            defaultPageSize: paging.pageSize,
            showSizeChanger: true,
            pageSizeOptions: pageSizeOptions,
            hideOnSinglePage: true,
            total: numTotalRecords,
            current: paging.page,
            showLessItems: false,
          }}
          onRow={(record: TableContractPartnerDataType) => ({
            onDoubleClick: () => {
              navigate(`/contract-partners/edit/${record.id}`, { state });
            },
          })}
        />
      </div>
    </>
  );
}

export default ContractPartnersList;
