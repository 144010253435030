import React, { useEffect } from "react";
import { useDebounceFn } from "ahooks";
import { Button, Form, Input, Select, Switch, Row, Col, Modal, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Fieldset } from "../../../Components/Fieldset/Fieldset";
import { useTranslation } from "react-i18next";
import { CondFulfillmentBaseIds, nonUpdateableFields, PerformanceProofTypes, ProcessingSystems } from "./Config";
import { useTree } from "../TreeContext";
import { mapConditionNodeToFormFields } from "../Mappers/FormMapper";

export const TreeBranchForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { selectedNode, updateSelectedNode, handleOnUpdateConditionTreeItem, handleOnCreateConditionTreeItem } =
    useTree();

  useEffect(() => {
    if (selectedNode && selectedNode.conditionNode) {
      form.setFieldsValue(mapConditionNodeToFormFields(selectedNode.conditionNode));
    } else {
      form.resetFields();
    }
  }, [selectedNode, form]);

  const isSelectedNodeFinalNode = (selectedNode?.optionNodeIds?.length ?? 0) > 0;
  const isRequired = selectedNode?.columnId === "CAT3" || selectedNode?.columnId === "CAT4";
  const isFieldsDisabled = isSelectedNodeFinalNode || !isRequired;

  const handleSubmit = (values: any) => {
    if (selectedNode?.nonUpdateableFieldsOriginalValues) {
      const updatedNonUpdatableFields = nonUpdateableFields.filter(
        (field) => values[field] !== selectedNode?.nonUpdateableFieldsOriginalValues?.[field],
      );

      if (updatedNonUpdatableFields.length > 0) {
        Modal.confirm({
          title: t("conditionTree.updateWarningTitle"),
          icon: <ExclamationCircleOutlined />,
          content: t("conditionTree.updateWarningMessage"),
          okText: t("conditionTree.confirm"),
          cancelText: t("conditionTree.cancel"),
          onOk: () => {
            handleOnUpdateConditionTreeItem();
          },
        });
      } else {
        handleOnUpdateConditionTreeItem();
      }
    } else {
      handleOnCreateConditionTreeItem();
    }
  };

  const { run: debouncedUpdateSelectedNode } = useDebounceFn(
    (values: any) => {
      updateSelectedNode(values);
    },
    { maxWait: 300 },
  );

  const handleFormUpdate = () => {
    if (!form.getFieldValue("isSellout")) {
      form.setFieldsValue({ koartSelloutAsSellin: undefined });
    }
    if (form.getFieldValue("koartParkingIn")) {
      form.setFieldsValue({ koartParkingOut: undefined });
      form.setFieldsValue({ koartInternational: undefined });
    }
    if (form.getFieldValue("koartParkingOut")) {
      form.setFieldsValue({ koartParkingIn: undefined });
      form.setFieldsValue({ koartInternational: undefined });
    }
    if (form.getFieldValue("koartInternational")) {
      form.setFieldsValue({ koartParkingIn: undefined });
      form.setFieldsValue({ koartParkingOut: undefined });
    }
    if (
      form.getFieldValue("isSellout") ||
      form.getFieldValue("isFixedAmount") ||
      form.getFieldValue("isMshParkingIn") ||
      form.getFieldValue("koartParkingIn") ||
      form.getFieldValue("koartParkingOut") ||
      form.getFieldValue("koartInternational") ||
      form.getFieldValue("koartReverseCharge")
    ) {
      form.setFieldsValue({ isStockProtection: false });
    }
    debouncedUpdateSelectedNode(form.getFieldsValue());
  };

  const proofOfPerformanceOptions = React.useMemo(
    () => PerformanceProofTypes.map((item) => ({ ...item, label: t(item.label) })),
    [t],
  );

  const WarningLabel = ({ label }: { label: string }) => (
    <>
      {!isFieldsDisabled && selectedNode?.nonUpdateableFieldsOriginalValues ? (
        <Tooltip title={t("conditionTree.nonUpdatableFieldTooltip")} color="gold" key={label}>
          {label} <ExclamationCircleOutlined style={{ color: "orange" }} />
        </Tooltip>
      ) : (
        label
      )}
    </>
  );

  const isCapOnContractIsAllowedDisabled = isFieldsDisabled || !form.getFieldValue("isSellout");
  const isKoartSelloutAsSellinDisabled = isFieldsDisabled || !form.getFieldValue("isSellout");
  const isKoartParkingOutDisabled =
    isFieldsDisabled || form.getFieldValue("koartParkingIn") || form.getFieldValue("koartInternational");
  const isKoartParkingInDisabled =
    isFieldsDisabled || form.getFieldValue("koartParkingOut") || form.getFieldValue("koartInternational");
  const isKoartInternationalDisabled =
    isFieldsDisabled || form.getFieldValue("koartParkingOut") || form.getFieldValue("koartParkingIn");
  const isStockProtectionDisabled =
    isFieldsDisabled ||
    form.getFieldValue("isSellout") ||
    form.getFieldValue("isFixedAmount") ||
    form.getFieldValue("isMshParkingIn") ||
    form.getFieldValue("koartParkingIn") ||
    form.getFieldValue("koartParkingOut") ||
    form.getFieldValue("koartInternational") ||
    form.getFieldValue("koartReverseCharge");

  return (
    <Fieldset title={t("conditionTree.formTitle")}>
      <Form
        form={form}
        name="control-hooks"
        onValuesChange={handleFormUpdate}
        onFinish={handleSubmit}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Form.Item
              name="name"
              label={t("conditionTree.name")}
              rules={[{ required: true, message: t("conditionTree.nameRequired") }]}
            >
              <Input disabled={false} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="processingSystem"
              label={<WarningLabel label={t("conditionTree.processingSystem")} />}
              rules={[{ required: true, message: t("conditionTree.processingSystemRequired") }]}
            >
              <Select placeholder={t("conditionTree.selectProcessingSystem")} options={ProcessingSystems} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="gkmKoart"
              label={t("conditionTree.gkmKoart")}
              rules={
                isRequired
                  ? [
                      { required: true, message: t("conditionTree.gkmKoartRequired") },
                      { max: 4, message: t("conditionTree.max4Digits") },
                    ]
                  : [{ max: 4, message: t("conditionTree.max4Digits") }]
              }
            >
              <Input maxLength={4} disabled={isFieldsDisabled} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="koartReverseCharge"
              label={t("conditionTree.koartReverseCharge")}
              rules={[{ max: 4, message: t("conditionTree.max4Digits") }]}
            >
              <Input maxLength={4} disabled={isFieldsDisabled} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="koartSelloutAsSellin"
              label={t("conditionTree.koartSelloutAsSellin")}
              rules={[{ max: 4, message: t("conditionTree.max4Digits") }]}
              shouldUpdate={(prevValues: Record<string, any>, currentValues: Record<string, any>) =>
                prevValues.isSellout !== currentValues.isSellout
              }
            >
              <Input maxLength={4} disabled={isKoartSelloutAsSellinDisabled} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="koartParkingOut"
              label={t("conditionTree.koartParkingOut")}
              rules={[{ max: 4, message: t("conditionTree.max4Digits") }]}
            >
              <Input maxLength={4} disabled={isKoartParkingOutDisabled} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="koartParkingIn"
              label={t("conditionTree.koartParkingIn")}
              rules={[{ max: 4, message: t("conditionTree.max4Digits") }]}
            >
              <Input maxLength={4} disabled={isKoartParkingInDisabled} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="koartInternational"
              label={t("conditionTree.koartInternational")}
              rules={[{ max: 4, message: t("conditionTree.max4Digits") }]}
            >
              <Input maxLength={4} disabled={isKoartInternationalDisabled} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item name="popType" label={t("conditionTree.proofOfPerformance")}>
              <Select
                placeholder={t("conditionTree.selectProofOfPerformance")}
                options={proofOfPerformanceOptions}
                disabled={isFieldsDisabled}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="condFulfillmentBaseId"
              label={<WarningLabel label={t("conditionTree.condFulfillmentBaseId")} />}
              rules={[
                {
                  required: form.getFieldValue("isSellout"),
                  message: t("conditionTree.condFulfillmentBaseIdRequired"),
                },
              ]}
            >
              <Select
                placeholder={t("conditionTree.selectFulfillmentBase")}
                options={CondFulfillmentBaseIds}
                disabled={isFieldsDisabled}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="amountIsPercentage"
              label={<WarningLabel label={t("conditionTree.amountIsPercentage")} />}
              valuePropName="checked"
            >
              <Switch disabled={isFieldsDisabled} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="isSellout"
              label={<WarningLabel label={t("conditionTree.isSellout")} />}
              valuePropName="checked"
            >
              <Switch disabled={isFieldsDisabled} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="isFixedAmount"
              label={<WarningLabel label={t("conditionTree.isFixedAmount")} />}
              valuePropName="checked"
            >
              <Switch disabled={isFieldsDisabled} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="isStockProtection"
              label={<WarningLabel label={t("conditionTree.isStockProtection")} />}
              valuePropName="checked"
            >
              <Switch disabled={isStockProtectionDisabled} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="isMshParkingIn"
              label={<WarningLabel label={t("conditionTree.isMshParkingIn")} />}
              valuePropName="checked"
            >
              <Switch disabled={isFieldsDisabled} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="capOnContractIsAllowed"
              label={<WarningLabel label={t("conditionTree.capOnContractIsAllowed")} />}
              valuePropName="checked"
            >
              <Switch disabled={isCapOnContractIsAllowedDisabled} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="isServiceCondition"
              label={<WarningLabel label={t("conditionTree.isServiceCondition")} />}
              valuePropName="checked"
            >
              <Switch disabled={isFieldsDisabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" gutter={12}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t("conditionTree.save")}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Fieldset>
  );
};
