interface RolesMap {
  [key: string]: string;
}

export const validityAreaOptions = [
  "LOCAL",
  "NATIONAL",
  "TRADEMARKETING",
  "RETAIL_MEDIA_INTERNATIONAL",
  "CALCULATORIC_CONDITIONS",
  "*",
];

export const rolesOptions: RolesMap = {
  QA: "CCM",
  CATMAN: "CCM",
  READONLY: "CCM",
  ACCOUNTING: "CCM",
  IP: "CCM",
  STORE: "CCM",
  USERADMIN: "CMADMIN",
  SUPERUSERADMIN: "CMADMIN",
  BUSINESSADMIN: "CMADMIN",
  MAINTENANCE: "CMADMIN",
};

export const defaultRoles = {
  salesChannelId: "*",
  clusterId: "*",
  storeId: ["*"],
  supplierId: ["*"],
  manufacturerId: ["*"],
  validityArea: ["*"],
  role: "",
};
