import { TreeProvider } from "./TreeContext";
import { ConditionTreeContainer } from "./ConditionTreeContainer";
import { TreeApiProvider } from "./TreeApiContext";

export const ConditionTree = () => (
  <TreeApiProvider>
    <TreeProvider>
      <ConditionTreeContainer />
    </TreeProvider>
  </TreeApiProvider>
);
