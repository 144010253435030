import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useUpdateConditionTreeItem } from "./Hooks/useUpdateConditionTreeItem";
import { useCreateConditionTreeItem } from "./Hooks/useCreateConditionTreeItem";
import { useGetConditionTree } from "./Hooks/useGetConditionTree";
import { countries, ValidityAreas } from "./Components/Config";
import { CreateConditionTreeItemDTO, MainDataNode, UpdateConditionTreeItemDTO } from "./types";

interface TreeApiContextType {
  treeData: MainDataNode[] | null;
  selectedCountry: string;
  selectedArea: string;
  updateData: any;
  createdData: any;
  updateError: any;
  createdError: any;
  setSelectedCountry: React.Dispatch<React.SetStateAction<string>>;
  setSelectedArea: React.Dispatch<React.SetStateAction<string>>;
  updateConditionTree: (updateConditionTreeItemDTO: UpdateConditionTreeItemDTO) => void;
  createConditionTree: (newConditionTreeItemDTO: CreateConditionTreeItemDTO) => void;
}

const TreeApiContext = createContext<TreeApiContextType | undefined>(undefined);

export const TreeApiProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedCountry, setSelectedCountry] = useState<string>(countries[0].value);
  const [selectedArea, setSelectedArea] = useState<string>(ValidityAreas[0].value);
  const { treeData, refetch } = useGetConditionTree(selectedCountry, selectedArea);
  const { updateConditionTreeItem, data: updateData, error: updateError } = useUpdateConditionTreeItem();
  const { createConditionTreeItem, data: createdData, error: createdError } = useCreateConditionTreeItem();

  const updateConditionTree = useCallback(
    (updateConditionTreeItemDTO: UpdateConditionTreeItemDTO) => {
      updateConditionTreeItem(updateConditionTreeItemDTO);
      refetch();
    },
    [updateConditionTreeItem, refetch],
  );

  const createConditionTree = useCallback(
    (newConditionTreeItemDTO: CreateConditionTreeItemDTO) => {
      createConditionTreeItem(newConditionTreeItemDTO);
      refetch();
    },
    [createConditionTreeItem, refetch],
  );

  const value = useMemo(
    () => ({
      treeData,
      selectedCountry,
      selectedArea,
      updateData,
      createdData,
      updateError,
      createdError,
      setSelectedCountry,
      setSelectedArea,
      updateConditionTree,
      createConditionTree,
    }),
    [
      treeData,
      selectedCountry,
      selectedArea,
      updateData,
      createdData,
      updateError,
      createdError,
      setSelectedCountry,
      setSelectedArea,
      updateConditionTree,
      createConditionTree,
    ],
  );

  return <TreeApiContext.Provider value={value}>{children}</TreeApiContext.Provider>;
};

export const useTreeApi = (): TreeApiContextType => {
  const context = useContext(TreeApiContext);
  if (!context) {
    throw new Error("useConditionTree must be used within a TreeApiProvider");
  }
  return context;
};
