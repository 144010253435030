import { useMutation } from "react-query";
import { UpdateConditionTreeItemDTO } from "../types";
import { resolver } from "../../../Config/serviceConfig";

export const useUpdateConditionTreeItem = () => {
  const {
    mutate: updateConditionTreeItem,
    data,
    error,
    isLoading,
  } = useMutation((item: UpdateConditionTreeItemDTO) => resolver.updateConditionTree(item), {
    onError: (error: any) => {
      console.error("Error updating condition tree item:", error?.response?.data?.message || error.message);
    },
    onSuccess: (data) => {
      console.log("Condition tree item updated successfully:", data);
    },
  });

  return {
    updateConditionTreeItem,
    data,
    error,
    isLoading,
  };
};
