import React, { useContext, useState, createContext, useCallback, useEffect } from "react";
import { Entities, FormFields, Node } from "./types";
import { entitiesUpdate } from "./Components/utils";
import { createEmptyConditionNode, initialEntities } from "./Components/Config";
import { mapFormFieldsToConditionNode } from "./Mappers/FormMapper";
import {
  mapNodeToCreateConditionTreeItemDTO,
  mapNodeToUpdateConditionTreeItemDTO,
} from "./Mappers/useConditionTreeApiMapper";
import { useTreeApi } from "./TreeApiContext";
import { mapToEntities } from "./Mappers/getConditionTreeMapper";

interface ContextType {
  entities: Entities;
  selectedNode: Node | undefined;
  selectedNodeIds: string[];
  isEditOpen: boolean;
  setEntities: React.Dispatch<React.SetStateAction<Entities>>;
  setSelectedNodeIds: React.Dispatch<React.SetStateAction<string[]>>;
  resetSelectedNode: () => void;
  addNewCat: (record: Node) => void;
  handleOnNodeSelect: (record: Node) => void;
  selectedTreePath: (record: Node) => void;
  handleOnEditNode: (record: Node) => void;
  updateSelectedNode: (form: FormFields) => void;
  handleOnUpdateConditionTreeItem: () => void;
  handleOnCreateConditionTreeItem: () => void;
}

const CountContext = createContext<ContextType | undefined>(undefined);

const TreeProvider = ({ children }: { children: React.ReactNode }) => {
  const [entities, setEntities] = useState<Entities>(initialEntities);
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([]);
  const [selectedNode, setSelectedNode] = useState<Node | undefined>(undefined);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

  const { treeData, updateConditionTree, createConditionTree } = useTreeApi();

  useEffect(() => {
    if (treeData) {
      setEntities((prevState) => mapToEntities(treeData, prevState));
    }
  }, [treeData, setEntities]);

  const resetSelectedNode = useCallback(() => {
    setSelectedNode(undefined);
  }, []);

  const handleOnUpdateConditionTreeItem = useCallback(() => {
    const updateConditionTreeItemDTO = mapNodeToUpdateConditionTreeItemDTO(selectedNodeIds, entities);
    updateConditionTree(updateConditionTreeItemDTO);
    resetSelectedNode();
  }, [selectedNodeIds, updateConditionTree, entities, resetSelectedNode]);

  const handleOnCreateConditionTreeItem = useCallback(() => {
    const newConditionTreeItemDTO = mapNodeToCreateConditionTreeItemDTO(selectedNodeIds, entities);
    createConditionTree(newConditionTreeItemDTO);
    resetSelectedNode();
  }, [selectedNodeIds, createConditionTree, entities, resetSelectedNode]);

  const updateSelectedNode = useCallback(
    (form: FormFields) => {
      if (selectedNode && selectedNode.conditionNode) {
        const updatedConditionNode = mapFormFieldsToConditionNode(form, selectedNode.conditionNode);

        setEntities((prevState) => {
          const updatedNodes = prevState.nodes.map((node) =>
            node.id === selectedNode.id
              ? {
                  ...node,
                  name: form.name,
                  conditionNode: updatedConditionNode,
                }
              : node,
          );

          return {
            ...prevState,
            nodes: updatedNodes,
          };
        });

        setSelectedNode((prev) =>
          prev
            ? {
                ...prev,
                name: form.name,
                conditionNode: updatedConditionNode,
              }
            : undefined,
        );
      }
    },
    [selectedNode, setEntities],
  );

  const updateEntities = useCallback(
    (record: Node) => {
      const currentCatNumber = Number(record.columnId.charAt(3));
      if (currentCatNumber < 4) {
        const newCatNumber = currentCatNumber + 1;
        const catTo = `CAT${newCatNumber}`;
        const updatedEntities = entitiesUpdate(entities, record, catTo);
        setEntities(updatedEntities);
      }
    },
    [entities, setEntities],
  );

  const selectedTreePath = useCallback(
    (record: Node) => {
      setSelectedNodeIds((prevState) => {
        const currentCatNumber = Number(record.columnId.charAt(3));

        // Filter out node IDs that belong to higher CATs
        const filteredNodeIds = prevState.filter((nodeId) => {
          const node = entities.nodes.find((node) => node.id === nodeId);
          if (!node || !node.columnId) return false; // Ensure node and columnId exist
          const nodeCatNumber = Number(node.columnId.charAt(3));
          return nodeCatNumber < currentCatNumber; // Retain nodes from lower CATs
        });

        // Add the new record ID
        return [...filteredNodeIds, record.id];
      });
    },
    [entities],
  );

  const handleOnEditNode = useCallback(
    (record: Node) => {
      updateEntities(record);
      selectedTreePath(record);
      setSelectedNode(record);
      setIsEditOpen(true);
    },
    [updateEntities, selectedTreePath],
  );

  const handleOnNodeSelect = useCallback(
    (record: Node) => {
      updateEntities(record);
      setIsEditOpen(false);
      selectedTreePath(record);
      setSelectedNode(record);
    },
    [updateEntities, selectedTreePath],
  );

  const addNewCat = useCallback(
    (record: Node) => {
      selectedTreePath(record);

      const currentCatNumber = Number(record.columnId.charAt(3));
      if (record.conditionNode && currentCatNumber < 4) {
        const newCatNumber = currentCatNumber + 1;
        const catTo = `CAT${newCatNumber}`;

        // Generate a unique ID for the new node
        const newNodeId = crypto.randomUUID();
        const newNode: Node = {
          id: newNodeId,
          name: "",
          conditionNode: createEmptyConditionNode(record.conditionNode),
          nonUpdateableFieldsOriginalValues: undefined,
          columnId: catTo,
          optionNodeIds: [],
        };

        // Add newNodeId to the current node
        const recordModified = {
          ...record,
          optionNodeIds: [...record.optionNodeIds, newNodeId],
        };

        const nodeModified = entities.nodes.map((node) => (node.id === recordModified.id ? recordModified : node));

        // Add new node to nodes
        const entitiesModified = {
          ...entities,
          nodes: [...nodeModified, newNode],
        };

        const updatedEntities = entitiesUpdate(entitiesModified, recordModified, catTo);

        setEntities(updatedEntities);
        setSelectedNodeIds((prevState) => [...prevState, newNodeId]);
        setSelectedNode(newNode);
        setIsEditOpen(true);
      }
    },
    [entities, selectedTreePath, setEntities],
  );

  const value = React.useMemo(
    () => ({
      entities,
      selectedNode,
      selectedNodeIds,
      isEditOpen,
      setSelectedNodeIds,
      resetSelectedNode,
      setEntities,
      addNewCat,
      handleOnNodeSelect,
      selectedTreePath,
      handleOnEditNode,
      updateSelectedNode,
      handleOnUpdateConditionTreeItem,
      handleOnCreateConditionTreeItem,
    }),
    [
      entities,
      selectedNode,
      selectedNodeIds,
      isEditOpen,
      resetSelectedNode,
      setEntities,
      addNewCat,
      handleOnNodeSelect,
      selectedTreePath,
      handleOnEditNode,
      updateSelectedNode,
      handleOnUpdateConditionTreeItem,
      handleOnCreateConditionTreeItem,
    ],
  );

  return <CountContext.Provider value={value}>{children}</CountContext.Provider>;
};

const useTree = () => {
  const context = useContext(CountContext);
  if (context === undefined) {
    throw new Error("useTree must be used within a TreeProvider");
  }
  return context;
};

export { TreeProvider, useTree };
