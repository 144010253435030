import { Button, Col, Row } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import { useTree } from "../TreeContext";
import { Node } from "../types";

interface Props {
  record: Node;
}

export const Cell = ({ record }: Props) => {
  const { addNewCat, handleOnEditNode } = useTree();

  const handleOnEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleOnEditNode(record);
  };

  const handleOnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    addNewCat(record);
  };

  return (
    <Row align="middle" style={{ width: "100%" }}>
      <Col span={16}>{record.name}</Col>
      <Col span={8} style={{ textAlign: "right" }}>
        <Button onClick={handleOnEdit} shape="circle" icon={<EditOutlined />} />
        {record.columnId !== "CAT4" && <Button onClick={handleOnClick} shape="circle" icon={<PlusOutlined />} />}
      </Col>
    </Row>
  );
};
