import { Result, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import NotAuthorized from "./Components/NotAuthorized/NotAuthorized";
import UserCreate from "./Pages/User/CreateUser/UserCreate";
import UserEdit from "./Pages/User/EditUser/UserEdit";
import UsersList from "./Pages/UsersList/UsersList";
import ContractPartnersList from "./Pages/ContractPartners/List/ContractPartnersList";
import AppContext from "./State/context/appContext";
import { doesHttpOnlyCookieExist } from "./utils/basic";
import Layout from "./Components/Layout/Layout";
import ContractPartnerEdit from "./Pages/ContractPartners/Edit/ContractPartnerEdit";
import ContractPartnerCreate from "./Pages/ContractPartners/Create/ContractPartnerCreate";
import { ConditionTree } from "./Pages/ConditionTree/ConditionTree";
import { useAuthorization } from "./Hooks/useAuthorization";


const App: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { userData, roles, serverVersion, isLoading, isError } = useAuthorization();

  if (!doesHttpOnlyCookieExist("user_session") && process.env.NODE_ENV !== "development" && process.env.CI !== "true") {
    window.location.href = `${process.env.REACT_APP_DOMAIN}/login`;
  }

  if (isLoading) {
    return <Spin size="large" />;
  }

  const accessibleRoutes = [
    { path: "/users-list", isAuthenticated: roles.isSuperUserAdmin || roles.isUserAdmin, component: UsersList },
    { path: "/user/create", isAuthenticated: roles.isSuperUserAdmin || roles.isUserAdmin, component: UserCreate },
    { path: "/user/edit/:id", isAuthenticated: roles.isSuperUserAdmin || roles.isUserAdmin, component: UserEdit },
    { path: "/contract-partners/list", isAuthenticated: roles.isBusinessAdmin, component: ContractPartnersList },
    { path: "/contract-partners/create", isAuthenticated: roles.isBusinessAdmin, component: ContractPartnerCreate },
    { path: "/contract-partners/edit/:id", isAuthenticated: roles.isBusinessAdmin, component: ContractPartnerEdit },
    { path: "/condition-tree", isAuthenticated: roles.isSuperUserAdmin || roles.isMaintenance, component: ConditionTree },
  ];

  const firstAccessibleRoute = accessibleRoutes.find((route) => route.isAuthenticated);
  const redirectTo = firstAccessibleRoute ? firstAccessibleRoute.path : "/not-authorized";

  if (isError && location.pathname !== "/not-authorized" && location.pathname !== "/error") {
    navigate("/error");
  }

  return (
    <AppContext.Provider value={{ user: { ...userData }, ...roles, serverVersion }}>
      <Layout>
        <Routes>
          {accessibleRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <PrivateRoute
                  redirect={redirectTo}
                  isAuthenticated={route.isAuthenticated}
                  Component={route.component}
                />
              }
            />
          ))}
          <Route path="/not-authorized" element={<NotAuthorized />} />
          <Route path="/error" element={<Result status="warning" title={t("app.error")} />} />
          <Route path="/*" element={<Navigate to={redirectTo} />} />
        </Routes>
      </Layout>
    </AppContext.Provider>
  );
};

export default App;

const PrivateRoute = ({
                        Component,
                        isAuthenticated,
                        redirect,
                      }: {
  Component: React.ComponentType;
  isAuthenticated: boolean;
  redirect: string;
}) => (isAuthenticated ? <Component /> : <Navigate to={redirect} />);
