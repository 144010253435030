import { matchSorter } from "match-sorter";
import { createServer, Response } from "miragejs";

import manufacturersList from "./db/manufacturers.json";
import storesList from "./db/stores.json";
import suppliersList from "./db/suppliers.json";
import user from "./db/user.json";
import usersList from "./db/usersList.json";
import countries from "./db/countries.json";
import contractPartnersList from "./db/contractPartner.json";
import purchaseSections from "./db/purchaseSections.json";
import tree from "./db/trees.json"

import PackageJsonInfoCache from "../../package.json";
import { MainDataNode } from "../Pages/ConditionTree/types";

const manufacturers = manufacturersList.length ? manufacturersList : [{ id: 0, name: "", countryId: "" }];
const stores = storesList.length ? storesList : [{ id: 0, name: "", sapCode: "" }];
const suppliers = suppliersList.length ? suppliersList : [{ id: 0, name: "", countryId: "", supplierShortId: 0 }];

const db = {
  manufacturers,
  stores,
  suppliers,
  user,
  usersList,
  countries,
  contractPartnersList,
  purchaseSections,
  tree,
};

interface MockServerProps {
  port: number;
}
export default function createMockServer({ port }: MockServerProps) {
  createServer({
    seeds(server) {
      server.db.loadData(db);
    },

    routes() {
      this.namespace = "api";
      this.urlPrefix = `http://localhost:3001/`;
      this.logging = false;

      this.get("/users", (schema) => {
        return schema.db.usersList;
      });

      this.post("/users/list", (schema) => {
        return({data: schema.db.usersList, total: schema.db.usersList.length})
      })

      this.get("/users/currentUser", () => {
        return new Response(
          200,
          {
            "Content-Type": "application/json",
            "X-Service-Version": PackageJsonInfoCache.version,
          },
          db.user,
        );
      });

      this.get("/logout", () => {
        return null;
      });

      this.get("/users/:id", (schema, request) => {
        const user: any = schema.db.usersList.find(request.params.id);
        user.userRights.filter((userRight: any) => {
          const supplier = db.suppliers.find((item: any) => item.id.toString() === userRight.supplierId?.toString());
          const store = db.stores.find((item: any) => item.id.toString() === userRight.storeId?.toString());
          const manufacturer = db.manufacturers.find(
            (item: any) => item.id.toString() === userRight.manufacturerId?.toString(),
          );
          userRight.supplier = {
            id: supplier?.id ?? "",
            name: supplier?.name ?? "",
            supplierShortId: supplier?.supplierShortId ?? 0,
          };
          userRight.store = {
            id: store?.id ?? "",
            name: store?.name ?? "",
            sapCode: store?.sapCode ?? "",
          };
          userRight.manufacturer = {
            id: manufacturer?.id ?? "",
            name: manufacturer?.name ?? "",
          };

          delete userRight.manufacturerId;
          delete userRight.supplierId;
          delete userRight.storeId;
          return userRight;
        });

        return user;
      });

      this.post("/users", (schema, request) => {
        const newUser = {
          id: Date.now().toString(),
          ...JSON.parse(request.requestBody),
          userRights: [],
          updatedBy: db.user.username,
          updatedAt: new Date().toISOString(),
          createdBy: db.user.username,
          createdAt: new Date().toISOString(),
        };

        const userExists = schema.db.usersList.some(
          (user) => user.email === newUser.email || user.username === newUser.username,
        );

        if (userExists) {
          return new Response(409);
        }

        schema.db.usersList.insert(newUser);
        return newUser;
      });

      this.get("/manufacturers", (_schema, request) => {
        const filter = String(request.queryParams?.filter);
        const filteredData = matchSorter(db.manufacturers, filter, {
          keys: ["id", "name"],
          threshold: matchSorter.rankings.CONTAINS,
        });

        return filteredData;
      });

      this.get("/suppliers", (_schema, request) => {
        const filter = String(request.queryParams?.filter);
        const filteredData = matchSorter(db.suppliers, filter, {
          keys: ["id", "name", "supplierShortId"],
          threshold: matchSorter.rankings.CONTAINS,
        });

        return filteredData;
      });

      this.get("/stores", (_schema, request) => {
        const filter = String(request.queryParams?.filter);
        const filteredData = matchSorter(db.stores, filter, {
          keys: ["sapCode", "name"],
          threshold: matchSorter.rankings.CONTAINS,
        });

        return filteredData;
      });

      this.get("/countries", (_schema) => {
        return db.countries;
      });

      this.put("/users/:id", (schema, request) => {
        const updatedUser = {
          ...schema.db.usersList.find(request.params.id),
          ...JSON.parse(request.requestBody),
          updatedBy: db.user.username,
          updatedAt: new Date().toISOString(),
        };
        const userExists = schema.db.usersList.some(
          (user) =>
            user.id !== updatedUser.id && (user.email === updatedUser.email || user.username === updatedUser.username),
        );

        if (userExists) {
          return new Response(409);
        }

        schema.db.usersList.update(request.params.id, updatedUser);
        return updatedUser;
      });

      this.delete("/users/:id", (schema, request) => {
        const user = schema.db.usersList.find(request.params.id);
        schema.db.usersList.remove(user);
        return user;
      });

      // Contract Partners
      this.get("/business-admin/contract-partners", (schema) => {
        return schema.db.contractPartnersList;
      });

      this.post("/business-admin/contract-partners/list", (schema) => {
        return({data: schema.db.contractPartnersList, total: schema.db.contractPartnersList.length})
      })

      this.get("/business-admin/contract-partners/:id", (schema, request) => {
        return schema.db.contractPartnersList.find(request.params.id);
      });

      this.get("/business-admin/purchase-sections", (schema) => {
        return schema.db.purchaseSections;
      });

      this.get("/business-admin/suppliers", (schema) => {
        return schema.db.suppliers;
      });

      this.post("/ui/conditiontree/query", (schema, request) => {
        const body = JSON.parse(request.requestBody);
        const validityArea = body.filter[0][0].value[0];
        const countryId = body.filter[1][0].value[0];
        const data = schema.db.tree[0].data.filter(
          (node: MainDataNode) =>
            node.countryId === countryId.toString() && node.validityArea === validityArea.toString()
        );
        return { ...schema.db.tree[0], data };
      });

      this.post("/conditiontree/item", (schema, request) => {
        const { parentId } = request.queryParams;

        if (!parentId) {
          return new Response(400, {}, { message: "Parent ID is required" });
        }

        const parentNode = schema.db.tree[0].data.find((node: any) =>
          [...node._items, ...node.items].some((item: any) => item.id.toString() === parentId)
        );

        if (!parentNode) {
          return new Response(404, {}, { message: "Parent node not found" });
        }

        const newItem = {
          id: Date.now(),
          parent: parentId,
          ...JSON.parse(request.requestBody),
          createdBy: db.user.username,
          createdAt: new Date().toISOString(),
          updatedBy: db.user.username,
          updatedAt: new Date().toISOString(),
        };

        const parent = [...parentNode._items, ...parentNode.items].find(
          (item: any) => item.id.toString() === parentId
        );

        if (parent) {
          parentNode._items.push(newItem);
        } else {
          return new Response(500, {}, { message: "Failed to add item to parent node" });
        }

        return newItem;
      });


      this.put("/conditiontree/item", (schema, request) => {
        const updatedItem = JSON.parse(request.requestBody);

        const treeNode = schema.db.tree[0].data.find((node: any) =>
          [...node._items, ...node.items].some((item: any) => item.id === updatedItem.id)
        );

        if (!treeNode) {
          return new Response(404, {}, { message: "Item not found" });
        }

        const existingItem = [...treeNode._items, ...treeNode.items].find(
          (item: any) => item.id === updatedItem.id
        );

        if (!existingItem) {
          return new Response(404, {}, { message: "Item not found in tree node" });
        }

        Object.assign(existingItem, {
          ...updatedItem,
          updatedBy: db.user.username,
          updatedAt: new Date().toISOString(),
        });

        return existingItem;
      });

      this.post("/business-admin/contract-partners", (schema, request) => {
        const newContractPartner = {
          id: Date.now().toString(),
          ...JSON.parse(request.requestBody),
          updatedBy: db.user.username,
          updatedAt: new Date().toISOString(),
          createdBy: db.user.username,
          createdAt: new Date().toISOString(),
        };

        const contractPartnerExists = schema.db.contractPartnersList.some(
          (contractPartner) => contractPartner.id === newContractPartner.id,
        );

        if (contractPartnerExists) {
          return new Response(409);
        }

        schema.db.contractPartnersList.insert(newContractPartner);
        return newContractPartner;
      });

      this.put("/business-admin/contract-partners/:id", (schema, request) => {
        const updateContractPartner = {
          ...schema.db.contractPartnersList.find(request.params.id),
          ...JSON.parse(request.requestBody),
          updatedBy: db.user.username,
          updatedAt: new Date().toISOString(),
        };

        const contractPartnerExists = schema.db.contractPartnersList.some(
          (contractPartner) => contractPartner.id === updateContractPartner.id,
        );

        if (contractPartnerExists) {
          return new Response(409);
        }

        schema.db.contractPartnersList.update(request.params.id, updateContractPartner);
        return updateContractPartner;
      });

      this.passthrough();
    },
  });
}
