import { ConditionNode, FormFields } from "../types";

export const mapConditionNodeToFormFields = (node: ConditionNode): FormFields => {
  return {
    name: node.name,
    amountIsPercentage: node.amountIsPercentage,
    isSellout: node.isSellout,
    isFixedAmount: node.isFixedAmount,
    gkmKoart: node.gkmKoart ?? "",
    koartReverseCharge: node.koartReverseCharge ?? "",
    processingSystem: node.processingSystem,
    koartInternational: node.koartInternational ?? "",
    koartParkingOut: node.koartParkingOut ?? "",
    koartSelloutAsSellin: node.koartSelloutAsSellin ?? "",
    isStockProtection: node.isStockProtection,
    isMshParkingIn: node.isMshParkingIn,
    capOnContractIsAllowed: node.capOnContractIsAllowed,
    condFulfillmentBaseId: node.condFulfillmentBaseId ?? undefined,
    isServiceCondition: node.isServiceCondition,
    popType: node.popType,
  };
};

export const mapFormFieldsToConditionNode = (formFields: FormFields, existingNode: ConditionNode): ConditionNode => {
  return {
    ...existingNode,
    name: formFields.name,
    amountIsPercentage: formFields.amountIsPercentage,
    isSellout: formFields.isSellout,
    isFixedAmount: formFields.isFixedAmount,
    gkmKoart: formFields.gkmKoart ?? existingNode.gkmKoart,
    koartReverseCharge: formFields.koartReverseCharge ?? existingNode.koartReverseCharge,
    processingSystem: formFields.processingSystem,
    koartInternational: formFields.koartInternational ?? existingNode.koartInternational,
    koartParkingOut: formFields.koartParkingOut ?? existingNode.koartParkingOut,
    koartSelloutAsSellin: formFields.koartSelloutAsSellin ?? existingNode.koartSelloutAsSellin,
    isStockProtection: formFields.isStockProtection,
    isMshParkingIn: formFields.isMshParkingIn,
    capOnContractIsAllowed: formFields.capOnContractIsAllowed,
    condFulfillmentBaseId: formFields.condFulfillmentBaseId ?? existingNode.condFulfillmentBaseId,
    isServiceCondition: formFields.isServiceCondition,
    popType: formFields.popType ?? existingNode.popType,
  };
};
